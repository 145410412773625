import React, {useState} from 'react';
import {returnPolicyHTML} from "./utils";

const BotPage = () => {


    return (
        <div className="position-relative overflow-hidden pt-1 pt-lg-2 form-section__wrapper">
            <div className='container'>
                <div className='row'>
                    <div className='card mb-3'>
                        <div className='card-body flat-card-body'>
                            <img className="mb-3" src='https://1443908614.rsc.cdn77.org/4604a726a73e074fee0c8ef302f6dcb48190324/bot.png'/>
                            <h1>Ваш персональный помощник для подбора займов</h1>
                            <p>Ищете лучшие условия для займа? <a href='https://liky.cc/H029'> Займу Бот </a> - это то, что вам нужно!</p>

                            <p>Наш уникальный телеграм-бот поможет вам найти займы с высоким шансом одобрения и
                                минимальными
                                комиссиями, экономя ваше время и деньги.</p>

                            <a href="https://liky.cc/H029" className='btn btn-lg btn-orange form-control mb-4 mt-2'>Подобрать займ</a>

                            <h3>Не упускайте возможность получить лучшие займы просто и быстро!
                                Начните использовать <a href="https://liky.cc/H029" style={{color: '#066ac9'}}>Займу Бот</a> – уже сегодня и сделайте ваш финансовый выбор проще и
                                выгоднее.</h3>

                            <a href="https://liky.cc/H029" className='btn btn-lg btn-primary form-control mb-4 mt-3'>Получить займ с
                                гарантией</a>

                            <p>Если у вас нет Telegram, не беспокойтесь – мы также предусмотрели возможность для
                                вас! </p>

                            <p>Посетите <a href="https://liky.cc/5eya"> наш сайт</a>, где вы найдете удобную витрину займов. Там вы сможете легко
                                просматривать и сравнивать различные предложения по займам, выбирая наиболее подходящие
                                вам условия без необходимости регистрироваться в Telegram.</p>

                            <p>Наш сайт предоставляет те же выгодные условия и удобный выбор, что и наш Telegram-бот.
                                Начните исследовать ваши финансовые возможности уже сегодня на <a href="https://liky.cc/5eya">нашем сайте</a> – найти
                                займ!</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default BotPage;
