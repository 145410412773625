import './App.css';
import './assets/vendor/font-awesome/css/all.min.css'
import './assets/vendor/bootstrap-icons/bootstrap-icons.css'
import './assets/vendor/tiny-slider/tiny-slider.css'
import './assets/vendor/glightbox/css/glightbox.css'
import './assets/css/style.css'
import {useState, useEffect} from "react";
import APIConfig from "./utills/api";
import {handleURLParams, returnPolicyHTML, shuffleArray} from "./utils";
import AdvCardItem from "./components/AdvCardItem";
import HeaderBlock from "./components/HeaderBlock";


function OffersPage() {
    const [offersList, setOffersList] = useState(null)
    const [loading, setLoading] = useState(true)
    const [offerUrlParams, setOfferUrlParams] = useState(null)
    const TOKEN = 'vUCjWJNiHH6U6DJGsiAzivZSermt3XfzdPAMk2nJv8o'

    const getUserOffers = async (sum = 0, period = 0, platform = 'BROKER') => {
        return await APIConfig.get(`entries?access_token=${TOKEN}&content_type=offer&fields.platform=${platform}&fields.amountFromNum[gte]=${sum}&fields.loanPeriod[gte]=${period}`);
    };

    useEffect(() => {
        setLoading(true)
        let params = handleURLParams()
        let platform = params[3]
        setOfferUrlParams(params[0])

        if (platform) {
            getUserOffers(0, 0, platform).then(res => {
                setOffersList(shuffleArray(res.data.items))
                setTimeout(() => {
                    setLoading(false)
                }, 900)
            })
        }
    }, [])

    return (
        <div className="position-relative overflow-hidden pt-1 pt-lg-2 form-section__wrapper">
            <div className='container'>
                {offersList && !loading ?
                    <div className="row">
                        <HeaderBlock/>
                        {offersList.map((offer, index) => {
                            return (
                                <div className="col-md-3 col-sm-12 mb-3 mb-md-0"
                                     style={{display: 'flex', justifyContent: 'center'}}
                                     key={index}>
                                    <AdvCardItem offer={offer} URLParams={offerUrlParams}/>
                                </div>
                            )
                        })}
                        <div>
                            {returnPolicyHTML()}
                        </div>


                    </div>
                    :
                    <section className='position-relative overflow-hidden pt-5 py-5 pt-lg-3 preloader__section'
                        style={{"height":"100vh","display":"flex","justifyContent":"center","alignItems":"center"}}
                    >
                        <p className='mt-3'>Подбираем персональные предложения...</p>
                    </section>
                }
            </div>
        </div>
    );
}

export default OffersPage;
