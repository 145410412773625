import './App.css';
import './assets/vendor/font-awesome/css/all.min.css'
import './assets/vendor/bootstrap-icons/bootstrap-icons.css'
import './assets/vendor/tiny-slider/tiny-slider.css'
import './assets/vendor/glightbox/css/glightbox.css'
import './assets/css/style.css'
import {useState, useEffect} from "react";
import APIConfig from "./utills/api";
import {handleURLParams} from "./utils";
import AdvCardItem from "./components/AdvCardItem";
import HeaderBlock from "./components/HeaderBlock";

import {Routes, Route} from 'react-router-dom';
import OffersPage from "./Offers";
import RedirectPage from "./go";
import Form from "./form";
import Bot from "./bot";
function App() {


    return (
        <Routes>
            <Route path="/" element={<OffersPage/>}/>
            <Route path="/offer" element={<RedirectPage/>}/>
            <Route path="/form" element={<Form/>}/>
            <Route path="/bot" element={<Bot/>}/>

        </Routes>
    );
}

export default App;
