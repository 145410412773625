import React, {useState} from 'react';
import {getNoun} from "../utils";

function AdvCardItem({offer, URLParams = null}) {
    const [mobile, setMobile] = useState(window.innerWidth <= 500);

    const handleOfferClick = (url) => {
        console.log('URLParams', URLParams)
        if (URLParams) {
            localStorage.setItem('direction', `${url}&${URLParams}`)
        } else {
            localStorage.setItem('direction', url)
        }
    }

    const randomLoans = () => {
        const loans = Math.round(Math.round(Math.random() * 100) * 4.2)
        const noun = getNoun(loans, ' займ ', ' займа ', ' займов ')
        return `${loans}${noun}`
    }
    return (
        <a onContextMenu={() => handleOfferClick(offer.fields.offerUrl)}
            onClick={() => handleOfferClick(offer.fields.offerUrl)} href="/offer"
            target={'_blank'} rel={'noreferrer'} style={
            mobile ? {maxWidth: '90%'} : {maxWidth: '100%'}
            }>
            <div className="card mb-3 shadow">
                <img
                    src={offer.fields.offerImageUrl}
                    className="card-img-top"
                    alt={offer.fields.offerName}
                    title={offer.fields.offerName}
                />
                <div className="card-body flat-card-body">
                    <div className="d-flex justify-content-between mb-2">
                    <span className={`form-control border-0 badge bg-success bg-opacity-10 text-success py-2`}>
                        {offer.fields.offerName}
                    </span>
                    </div>
                    <h5 className="card-title mt-3">{offer.fields.amountFrom}</h5>


                    <ul className="list-inline mb-3">
                        <li className="list-inline-item me-0 ">
                            <i className="fas fa-star text-warning"/>
                        </li>
                        <li className="list-inline-item me-0 ">
                            <i className="fas fa-star text-warning"/>
                        </li>
                        <li className="list-inline-item me-0 ">
                            <i className="fas fa-star text-warning"/>
                        </li>
                        <li className="list-inline-item me-0 ">
                            <i className="fas fa-star text-warning"/>
                        </li>
                        {(Math.round(Math.random() * 10) % 3) >= 1
                            ?
                            <li className="list-inline-item me-0 ">
                                <i className="fas fa-star text-warning"/>
                            </li>
                            :
                            <li className="list-inline-item me-0 ">
                                <i className="fas fa-star-half-alt text-warning"/>
                            </li>
                        }
                    </ul>

                    <div className="card-footer pt-0 pb-3" style={{marginRight: '-11px', marginLeft: '-15px'}}>
                        <hr/>
                        <div className="d-flex justify-content-between">
                            <span className="h6 fw-light mb-0" style={{fontSize: '12px'}}>
                                <i className="far fa-regular fa-thumbs-up text-danger me-2"/>
                                {randomLoans()} одобрили сегодня
                            </span>
                        </div>
                    </div>

                    <button className="btn btn-lg btn-orange form-control">
                        Получить деньги
                    </button>
                </div>


            </div>
        </a>
    )
}

export default AdvCardItem