export const handleURLParams = () => {
    const location = window.location;
    const params = new URLSearchParams(location.search);
    let sum = 0;
    let period = 0;
    let platform = null

    let offerURLParams = new URLSearchParams(location.search)

    if (params.has('sum')) {
        sum = params.get('sum');
        offerURLParams.delete('sum')
    }
    if (params.has('period')) {
        period = params.get('period');
        offerURLParams.delete('period')
    }
    if (params.has('platform')) {
        platform = params.get('platform');
        offerURLParams.delete('platform')
    }

    return [offerURLParams.toString(), sum, period, platform]
}

export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function getNoun(number, one, two, five) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
}


export const returnPolicyHTML = () => {
    return (

        <div className="flex flex-wrap mt-5">
            <div className="flex-1">
                <h3 className="text-base leading-7 text-slate-700">Информация</h3>
                <p className="text-base leading-7 text-slate-700">
                    Займы от партнеров выдаются в российских рублях, на расчетный счет, наличными или на карту,
                    гражданам РФ.
                </p>
                <p className="mt-4 text-base leading-7 text-slate-700">
                    Сайт не осуществляет выдачу кредитов и займов, носит чисто информационный характер.
                    ИНН 9723030878
                    ОГРН 1177746621414
                    Телефон +7908914636
                    Юридическое наименование: LEADS.SU
                    ООО "ЛИДС"
                    105082, Г.Москва, вн.тер.г. Муниципальный Округ Басманный, ул Бакунинская, д. 69, стр. 1
                </p>
                <p className="mt-4 text-base leading-7 text-slate-700">
                    Доступные суммы для займа: от 1 000 рублей до 100 000 рублей. Срок займа: от 62 дней, процентная
                    ставка: от 0% до 365% годовых.
                </p>
                <p className="mt-4 text-base leading-7 text-slate-700">
                    Оставаясь на сайте, а также совершая любые действия на сайте, вы принимаете условия
                    пользовательского соглашения, договора публичной оферты, политики обработки персональных данных, а
                    также даете согласие на получение рекламных материалов.
                </p>
            </div>
            <div className="flex-1">
                <p className="mt-4 text-base leading-7 text-slate-700">
                    <h3>Наши партнеры</h3>
                    <p style={{marginBottom: 10}}>
                        Екапуста ООО МКК «Русинтерфинанс» Лицензия ЦБ РФ: 2120754001243 Адрес: 630055, Новосибирская
                        область, г. Новосибирск, ул. Гнесиных, д. 10/1, оф. 202
                    </p>
                    <p style={{marginBottom: 10}}>
                        Moneza ООО МКК «МАКРО» Лицензия ЦБ РФ: 001603045007582 Адрес: 115114, г. Москва, ул.
                        Летниковская, д. 10, стр. 4, пом. I, комн. 15
                    </p>
                    <p style={{marginBottom: 10}}>
                        Money Man ООО МФК "Мани Мен" Лицензия ЦБ РФ: 2110177000478 Адрес: 121096, г. Москва, ул.
                        Василисы Кожиной, д. 1, оф. Д13
                    </p>
                    <p style={{marginBottom: 10}}>
                        Turbozaim ООО МФК «Турбозайм» Лицензия ЦБ РФ: 651303045003951 Адрес: 123290, г.Москва,
                        Магистральный 1-й туп., д.11, стр.10, пом.1, эт.1
                    </p>
                    <p style={{marginBottom: 10}}>
                        Webbankir ООО МФК «ВЭББАНКИР» Лицензия ЦБ РФ: 2120177002077 Адрес: 125466, г. Москва, ул.
                        Соколово-Мещерская, д. 29, оф. 308
                    </p>
                </p>
            </div>
        </div>
    )
}