import React, {useState, useEffect} from 'react'

export default function RedirectPage() {
    const [offerUrl, setOfferUrl] = useState(null);

    useEffect(() => {
        const offerURL = localStorage.getItem('direction') || 'https://google.com';
        setOfferUrl(offerURL)
        window.location.replace(offerURL)
    }, []);


    return (
        <>
            {/*<p>312312312{offerUrl}</p>*/}
        </>
    )
}
