import React, {useState} from 'react';
import {returnPolicyHTML} from "./utils";

const SimpleForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        agreeToMailing: false,
    });
    const [formSent, setFormSent] = useState(false)

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        setFormSent(true)
    };

    return (
        <div className="position-relative overflow-hidden pt-1 pt-lg-2 form-section__wrapper">
            <div className='container'>
                {!formSent ?
                    <div className="row py-3">
                        <h3>Подобрать персональные предложения займа</h3>
                        <p>Оставьте заявку и мы пришлем вам персональную подборку с займами от лучших МФО</p>
                        <form onSubmit={handleSubmit} className="space-y-4
                d-flex justify-content-center align-items-center flex-column">
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                placeholder="Имя"
                                className="block w-full p-2 border border-gray-300 rounded-md form-control mb-3"
                            />
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                placeholder="Фамилия"
                                className="block w-full p-2 border border-gray-300 rounded-md form-control mb-3"
                            />
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Email"
                                className="block w-full p-2 border border-gray-300 rounded-md form-control mb-3"
                            />
                            <input
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="Номер телефона"
                                className="block w-full p-2 border border-gray-300 rounded-md form-control mb-3"
                            />
                            <label className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    name="agreeToMailing"
                                    checked={formData.agreeToMailing}
                                    onChange={handleChange}
                                    className="form-checkbox h-5 w-5 "
                                />
                                <span>
                            Оставляя заявку я соглашаюсь с <a href="https://www.nsvz.ru/public-offer-agreement.pdf">политикой конфиденциальности</a> ,
                            <a href="https://www.nsvz.ru/privacy-policy.pdf"> обработки персональных данных </a>, а также даю согласие на
                            получение рекламных материалов.
                        </span>
                            </label>
                            <button type="submit" className="btn btn-lg btn-orange form-control mt-3">
                                Подобрать займ
                            </button>
                        </form>
                    </div>
                    :
                    <div className="row py-3">
                        <h3>Спасибо, ваша заявка принята, в ближайшее время мы пришлем вам персональные предложения</h3>
                    </div>
                }
                <div className="row">
                    {returnPolicyHTML()}
                </div>
            </div>
        </div>
    );
};

export default SimpleForm;
