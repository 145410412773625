import {useState} from "react";

function HeaderBlock() {

    return (
        <div className="filter-block" style={{
            "color": "#000",
            "fontFamily": "Open Sans, sans-serif",
            "fontSize": "16px",
            "boxSizing": "border-box",
            "WebkitBoxAlign": "center",
            "WebkitBoxPack": "center",
            "alignItems": "center",
            "borderRadius": "30px",
            "display": "flex",
            "height": "207px",
            "justifyContent": "center",
            "marginTop": "20px",
            "overflow": "hidden",
            "position": "relative",
            "textAlign": "center",
            "marginBottom": '30px',

        }}>
            <svg
                width={1440}
                height={207}
                viewBox="0 0 1440 207"
                preserveAspectRatio="xMinYMin slice"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    "height": "100%",
                    "OObjectFit": "cover",
                    "objectFit": "cover",
                    "position": "absolute",
                    "width": "100%"
                }}
            >
                <rect width={1440} height={207} rx={30} fill="#7AC362"/>
                <mask
                    id="mask0"
                    style={{maskType: "alpha"}}
                    maskUnits="userSpaceOnUse"
                    x={0}
                    y={0}
                    width={1440}
                    height={207}
                >
                    <rect width={1440} height={207} rx={30} fill="#52B6FF"/>
                </mask>
                <g mask="url(#mask0)">
                    <rect
                        x="172.792"
                        y={-215}
                        width={187}
                        height={641}
                        rx={30}
                        transform="rotate(33.5 172.792 -215)"
                        fill="url(#paint0_linear)"
                    />
                    <rect
                        x="1370.87"
                        y={-215}
                        width={187}
                        height={641}
                        rx={30}
                        transform="rotate(33.5 1370.87 -215)"
                        fill="url(#paint1_linear)"
                    />
                    <rect
                        x="692.141"
                        y="-195.958"
                        width={118}
                        height={641}
                        rx={30}
                        transform="rotate(33.5 692.141 -195.958)"
                        fill="url(#paint2_linear)"
                    />
                    <rect
                        x="869.047"
                        y="-170.207"
                        width="56.343"
                        height={641}
                        rx="28.1715"
                        transform="rotate(33.5 869.047 -170.207)"
                        fill="url(#paint3_linear)"
                    />
                    <rect
                        x="973.218"
                        y="-202.856"
                        width={143}
                        height={641}
                        rx={30}
                        transform="rotate(33.5 973.218 -202.856)"
                        fill="url(#paint4_linear)"
                    />
                    <rect
                        x="1206.65"
                        y="-187.679"
                        width={88}
                        height={641}
                        rx={30}
                        transform="rotate(33.5 1206.65 -187.679)"
                        fill="url(#paint5_linear)"
                    />
                    <rect
                        x="1141.58"
                        y="-170.846"
                        width={27}
                        height={641}
                        rx="13.5"
                        transform="rotate(33.5 1141.58 -170.846)"
                        fill="url(#paint6_linear)"
                    />
                    <rect
                        x="401.084"
                        y="-172.775"
                        width={34}
                        height={641}
                        rx={17}
                        transform="rotate(33.5 401.084 -172.775)"
                        fill="url(#paint7_linear)"
                    />
                    <rect
                        x="1600.16"
                        y="-172.775"
                        width={34}
                        height={641}
                        rx={17}
                        transform="rotate(33.5 1600.16 -172.775)"
                        fill="url(#paint8_linear)"
                    />
                    <rect
                        x="496.285"
                        y="-181.057"
                        width={64}
                        height={641}
                        rx={30}
                        transform="rotate(33.5 496.285 -181.057)"
                        fill="url(#paint9_linear)"
                    />
                    <rect
                        x="589.796"
                        y="-169.465"
                        width={22}
                        height={641}
                        rx={11}
                        transform="rotate(33.5 589.796 -169.465)"
                        fill="url(#paint10_linear)"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="294.082"
                        y1="0.392523"
                        x2="293.144"
                        y2="496.134"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7AC362"/>
                        <stop offset={1} stopColor="#A7CE8E"/>
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="1492.16"
                        y1="0.392523"
                        x2="1491.22"
                        y2="496.134"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7AC362"/>
                        <stop offset={1} stopColor="#A7CE8E"/>
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear"
                        x1="768.677"
                        y1="19.4345"
                        x2="767.191"
                        y2="515.173"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7AC362"/>
                        <stop offset={1} stopColor="#A7CE8E"/>
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear"
                        x1="905.592"
                        y1="45.186"
                        x2="902.479"
                        y2="540.91"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7AC362"/>
                        <stop offset={1} stopColor="#A7CE8E"/>
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear"
                        x1="1065.97"
                        y1="12.5361"
                        x2="1064.74"
                        y2="508.276"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7AC362"/>
                        <stop offset={1} stopColor="#A7CE8E"/>
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear"
                        x1="1263.73"
                        y1="27.7133"
                        x2="1261.73"
                        y2="523.449"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7AC362"/>
                        <stop offset={1} stopColor="#A7CE8E"/>
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear"
                        x1="1159.1"
                        y1="44.5468"
                        x2="1152.6"
                        y2="540.205"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7AC362"/>
                        <stop offset={1} stopColor="#A7CE8E"/>
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear"
                        x1="423.137"
                        y1="42.6171"
                        x2="417.979"
                        y2="538.307"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7AC362"/>
                        <stop offset={1} stopColor="#A7CE8E"/>
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear"
                        x1="1622.22"
                        y1="42.6171"
                        x2="1617.06"
                        y2="538.307"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7AC362"/>
                        <stop offset={1} stopColor="#A7CE8E"/>
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear"
                        x1="537.796"
                        y1="34.3359"
                        x2="535.056"
                        y2="530.064"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7AC362"/>
                        <stop offset={1} stopColor="#A7CE8E"/>
                    </linearGradient>
                    <linearGradient
                        id="paint10_linear"
                        x1="604.066"
                        y1="45.9272"
                        x2="596.095"
                        y2="541.542"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#7AC362"/>
                        <stop offset={1} stopColor="#A7CE8E"/>
                    </linearGradient>
                </defs>
            </svg>
            <div style={{'position': 'absolute'}}>
                <div className="filter-block__title"
                     style={{"color": "#fff", "fontSize": "30px", "fontWeight": "700", "textAlign": "center"}}
                >Микрозаймы онлайн на&nbsp;карту
                </div>
                <div className="filter-block__text" style={{
                    "color": "#fff",
                    "fontWeight": "400",
                    "height": "44px",
                    "margin": "0 auto",
                    "paddingTop": "20px",
                    "textAlign": "center",
                    "width": "388px"
                }}>
                    Для гарантированного получения денег, нужно оставить заявку минимум в 3 МФО
                </div>
            </div>
        </div>
    )
}

export default HeaderBlock