import axios from "axios";

let url = "https://cdn.contentful.com/spaces/8uqbxq8tw9c8/";


const APIConfig = axios.create({
    baseURL: url,
    responseType: "json"
});

export default APIConfig;